import type { ObjectValues } from "../../lib/utils/types.ts"

export const CART_FETCHERS = {
  ADD_LINES: "cart/add-lines",
  UPDATE_LINES: "cart/update-lines",
  REMOVE_LINES: "cart/remove-lines",
  APPLY_POINTS: "cart/apply-points",
  REMOVE_POINTS: "cart/remove-points",
  ADD_REFERRAL_DATA: "cart/add-referral-data",
} as const

const CART_FETCHER_KEYS = Object.values(CART_FETCHERS)

export function isCartFetcherKey(key: string): key is CartFetcherKey {
  return CART_FETCHER_KEYS.includes(key as CartFetcherKey)
}

export type CartFetcherKey = ObjectValues<typeof CART_FETCHERS>
