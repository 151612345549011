import type { CartActionInput, CartQueryDataReturn } from "@shopify/hydrogen"
import type { SelectedOption } from "@shopify/hydrogen/storefront-api-types"
import type {
  ProductFragment,
  ProductVariantFragment,
} from "./types/storefront.generated"

/* This is the default option for a product with no variants. */
const SHOPIFY_DEFAULT_OPTION: ProductOption = { name: "Title", value: "Default Title" }

/**
 * Get a formatted string of variant options.
 *
 * @param options - The variant options to format.
 * @returns A formatted string of variant options.
 */
export function getFormattedVariantOptions(options: SelectedOption[]) {
  return options
    .filter((option) => getOptionKey(option) !== getOptionKey(SHOPIFY_DEFAULT_OPTION))
    .map((option) => option.value)
    .join(" • ")
}

/**
 * Get the default variant from a list of variants.
 *
 * @param variants - The list of variants to search through.
 * @returns The default variant.
 */
export function getDefaultVariant(variants: Array<ProductVariantFragment>) {
  return variants.find((v) => v.availableForSale) ?? variants[0]
}

/**
 * Get a variant from a list of variants based on the selected options.
 *
 * @param args - The arguments to search for the variant.
 * @returns The variant that matches the selected options.
 */
export function getVariantFromOptions(args: {
  variants: Array<ProductVariantFragment>
  options: ProductOption[]
}) {
  const { variants, options } = args

  return (
    variants.find((v) =>
      options.every((o) =>
        v.selectedOptions.find((so) => getOptionKey(so) === getOptionKey(o)),
      ),
    ) ?? null
  )
}

/**
 * Get the options available for sale from a list of variants.
 *
 * @param variants - The list of variants to search through.
 * @returns A map of options available for sale.
 */
export function getOptionsAvailableForSale(variants: Array<ProductVariantFragment>) {
  const optionsMap = variants.reduce<Map<string, ProductOption>>((acc, variant) => {
    if (variant.availableForSale) {
      const variantOptions = variant.selectedOptions.map((option) => ({
        option,
        key: getOptionKey(option),
      }))

      variantOptions.forEach((option) => {
        acc.set(option.key, option.option)
      })
    }

    return acc
  }, new Map())

  return Array.from(optionsMap.values())
}

/** Generate a unique key for a product option. */
export function getOptionKey(option: ProductOption) {
  return `${option.name}-${option.value}`
}

/** Submits a cart action following the convention of Shopify's CartForm component */
export async function triggerCartAction({
  action,
  inputs,
}: {
  action: CartActionInput["action"]
  inputs?: CartActionInput["inputs"]
}) {
  const formData = new FormData()
  formData.append("cartFormInput", JSON.stringify({ action, inputs }))
  const res = await fetch("/cart", {
    method: "POST",
    body: formData,
  })
  return (await res.json()) as CartQueryDataReturn
}

export type ProductOption = ProductVariantFragment["selectedOptions"][number]
export type ProductOptionWithValues = ProductFragment["options"][number]
export type ProductWithVariants = ProductFragment & {
  variants: { nodes: ProductVariantFragment[] }
}
