import type {
  CartLineUpdateInput,
  MoneyV2,
} from "@shopify/hydrogen/storefront-api-types"
import type { CartData } from "../../lib/shopify/cart.server.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as Shopify from "../../lib/shopify/shopify.ts"
import * as Sheet from "../../lib/ui/sheet.tsx"
import * as IykRef from "../../lib/utils/iyk-ref.ts"

import { toSentenceCase } from "@iyk/string"
import { Await, Link } from "@remix-run/react"
import { CartForm, useMoney } from "@shopify/hydrogen"
import { PRIVACY_POLICY_URL, TERMS_AND_SERVICES_URL } from "../../lib/constants.ts"
import { useLang } from "../../lib/lang/use-lang.ts"
import { CUSTOM_CART_ACTIONS } from "../../lib/shopify/cart.ts"
import { Collapsible } from "../../lib/ui/collapsible.tsx"
import { CART_FETCHERS } from "./cart-fetchers.ts"
import { useCart } from "./cart-provider.tsx"
import { usePendingCartActions } from "./use-pending-cart-actions.ts"

// #region Cart

function Cart() {
  const { cart } = useCart()

  return (
    <CartSheet>
      <React.Suspense fallback={<CartFallback />}>
        <Await resolve={cart}>{(cartData) => <CartMain cart={cartData} />}</Await>
      </React.Suspense>
    </CartSheet>
  )
}

function CartMain({ cart }: { cart: CartData | null }) {
  const totalQuantity = cart?.totalQuantity ?? 0

  return (
    <div className="h-full flex flex-col">
      <CartHeader totalQuantity={totalQuantity} />
      {cart && totalQuantity > 0 ? (
        <CartProvider cart={cart}>
          <div className="flex-1 flex flex-col overflow-y-auto">
            {cart.teamCarts.map((teamCart, index, array) => (
              <Collapsible.Root
                key={teamCart.team.id}
                id={teamCart.team.id.toString()}
                className={UI.cx(
                  "bshadow-t bshadow-gray-3 mx-3",
                  index === array.length - 1 && "flex-1",
                )}
              >
                <Collapsible.Trigger className="py-4 justify-between">
                  <CartLineTitle teamCartIndex={index} />
                </Collapsible.Trigger>
                <Collapsible.Content className="data-[open=true]:pb-4">
                  <CartLineList teamCartIndex={index} />
                </Collapsible.Content>
              </Collapsible.Root>
            ))}
            <CartSummary />
          </div>
          <CartFooter />
        </CartProvider>
      ) : (
        <CartEmpty />
      )}
    </div>
  )
}

// #endregion

// #region CartProvider

type CartContextValue = {
  cart: CartData
  getTeamCart: (teamCartIndex: number) => CartData["teamCarts"][number]
}

const CartContext = React.createContext<CartContextValue | undefined>(undefined)

const CartProvider = ({
  children,
  cart,
}: {
  children: React.ReactNode
  cart: CartData
}) => {
  function getTeamCart(teamCartIndex: number) {
    return cart.teamCarts[teamCartIndex]
  }

  return (
    <CartContext.Provider value={{ cart, getTeamCart }}>
      {children}
    </CartContext.Provider>
  )
}

const useCartContext = () => {
  const context = React.useContext(CartContext)
  if (!context) {
    throw new Error("useCartContext must be used within a CartContext")
  }
  return context
}

// #endregion

// #region CartSheet

function CartSheet({ children }: { children: React.ReactNode }) {
  const lang = useLang()
  const cartContext = useCart()
  const onOpenChange = (newIsOpen: boolean) =>
    newIsOpen ? cartContext.open() : cartContext.close()

  return (
    <Sheet.Root open={cartContext.isOpen} onOpenChange={onOpenChange}>
      <div className="sr-only">
        <Sheet.Title>{toSentenceCase(lang.terms.BAG)}</Sheet.Title>
        <Sheet.Description>
          {toSentenceCase(lang.terms.VIEW_AND_MANAGE_BAG)}
        </Sheet.Description>
      </div>
      <Sheet.Content className="h-full flex flex-col" size="none">
        {children}
      </Sheet.Content>
    </Sheet.Root>
  )
}

// #endregion

// #region CartFallback

function CartFallback() {
  const lang = useLang()
  return (
    <span className="text-sm text-gray-11 p-3">
      {toSentenceCase(lang.terms.LOADING_BAG)}
    </span>
  )
}

// #endregion

// #region CartHeader

function CartHeader({ totalQuantity }: { totalQuantity: CartData["totalQuantity"] }) {
  const lang = useLang()
  return (
    <div className="flex items-center gap-2 px-3 pt-3 mb-8">
      <span className="text-xs">{toSentenceCase(lang.terms.BAG)}</span>
      <span className="text-xs">{totalQuantity}</span>
    </div>
  )
}

// #endregion

// #region CartEmpty

function CartEmpty() {
  const lang = useLang()
  return (
    <span className="text-sm text-gray-11 px-3">
      {toSentenceCase(lang.terms.EMPTY_BAG)}
    </span>
  )
}

// #endregion

// #region CartLineTitle

function CartLineTitle({ teamCartIndex }: { teamCartIndex: number }) {
  const lang = useLang()
  const { getTeamCart } = useCartContext()
  const teamCart = getTeamCart(teamCartIndex)

  return (
    <div className="flex items-center gap-2">
      <UI.Avatar
        variant="rounded"
        src={teamCart.team.iconUrl ?? ""}
        alt={teamCart.team.name}
        className="size-5 overflow-hidden shrink-0"
      />
      <div className="flex items-baseline gap-1.5">
        <span className="text-sm">{teamCart.team.name}</span>
        {teamCart.userPointsBalance > 0 && (
          <span className="text-[11px] leading-[14px] font-departure-mono uppercase">
            {teamCart.userPointsBalance} {lang.terms.POINTS}
          </span>
        )}
      </div>
    </div>
  )
}

// #endregion

// #region CartLineList

function CartLineList({ teamCartIndex }: { teamCartIndex: number }) {
  const { getTeamCart } = useCartContext()
  const teamCart = getTeamCart(teamCartIndex)

  return (
    <div className="flex flex-col gap-3">
      {teamCart.lines.map((line) => (
        <CartLine key={line.id} line={line} />
      ))}
      <CartLinePointsSection teamCart={teamCart} />
      <CartLineSubtotal
        pointsDiscount={teamCart.pointsDiscount}
        subtotal={teamCart.cost.subtotalAmount}
        totalAmount={teamCart.cost.totalAmount}
      />
    </div>
  )
}

// #endregion

// #region CartLineSubtotal

function CartLineSubtotal({
  pointsDiscount,
  subtotal,
  totalAmount,
}: {
  pointsDiscount: CartData["teamCarts"][number]["pointsDiscount"]
  subtotal: CartData["teamCarts"][number]["cost"]["subtotalAmount"]
  totalAmount: CartData["teamCarts"][number]["cost"]["totalAmount"]
}) {
  const lang = useLang()

  return (
    <div className="flex flex-col gap-1.5 text-xs">
      <div className="flex justify-between items-center">
        <span>{toSentenceCase(lang.terms.CREATOR_SUBTOTAL)}:</span>
        <CartPriceBreakdown
          subtotalAmount={subtotal}
          totalAmount={totalAmount}
          hasDiscount={Boolean(pointsDiscount)}
          className="text-xs"
        />
      </div>
    </div>
  )
}

// #endregion

// #region CartLinePointsSection

function CartLinePointsSection({
  teamCart,
}: {
  teamCart: CartData["teamCarts"][number]
}) {
  const lang = useLang()
  const { pendingCartActions } = usePendingCartActions()

  const isCartLineUpdating = pendingCartActions.some(
    (action) => action.inputs.teamId === teamCart.team.id,
  )

  if (teamCart.userPointsBalance === 0) return null

  return (
    <div className="flex flex-col bg-points-muted bshadow bshadow-points font-departure-mono text-[11px] leading-[14px] uppercase">
      <div className="flex h-10 *:px-3">
        <div className="flex-1 flex items-center gap-2">
          {teamCart.pointsApplied > 0 ? (
            <>
              <Icon.Check className="bg-points" />
              <span>
                {lang.terms.POINTS_APPLIED}: {teamCart.pointsApplied}
              </span>
            </>
          ) : (
            <>
              <Icon.Points className="bg-points" />
              <span>
                {teamCart.userEligiblePointsBalance} {lang.terms.POINTS_ELIGIBLE}
              </span>
            </>
          )}
        </div>
        <div className="bshadow-l bshadow-points content-center text-center w-[68px]">
          <CartForm
            key={teamCart.team.id}
            route="/cart"
            action={
              teamCart.pointsApplied > 0
                ? CUSTOM_CART_ACTIONS.REMOVE_POINTS
                : CUSTOM_CART_ACTIONS.APPLY_POINTS
            }
            inputs={{ teamId: teamCart.team.id }}
            fetcherKey={
              teamCart.pointsApplied > 0
                ? CART_FETCHERS.REMOVE_POINTS
                : CART_FETCHERS.APPLY_POINTS
            }
          >
            <button
              className="underline uppercase"
              type="submit"
              disabled={isCartLineUpdating}
            >
              {isCartLineUpdating ? (
                <Icon.LoadingSpinner className="size-3" />
              ) : teamCart.pointsApplied > 0 ? (
                lang.terms.UNDO
              ) : (
                lang.terms.APPLY
              )}
            </button>
          </CartForm>
        </div>
      </div>
      {teamCart.pointsApplied > 0 && (
        <div className="flex justify-between items-center gap-2 h-10 bshadow-t bshadow-points *:px-3">
          <span>{lang.terms.REMAINING_BALANCE}:</span>
          <span>{teamCart.userAvailablePointsBalance} PTS</span>
        </div>
      )}
    </div>
  )
}

// #endregion

// #region CartLine

function CartLine({ line }: { line: Line }) {
  const optionsText = Shopify.getFormattedVariantOptions(
    line.merchandise.selectedOptions,
  )

  const lang = useLang()

  const { pendingCartActions } = usePendingCartActions()
  const isUpdating = pendingCartActions.some(
    (action) =>
      (action.action === "LinesUpdate" &&
        action.inputs.lines.some((input) => input.id === line.id)) ||
      (action.action === "LinesRemove" && action.inputs.lineIds.includes(line.id)),
  )

  const { subtotalAmount, totalAmount } = line.cost
  const hasDiscount = Number(totalAmount.amount) < Number(subtotalAmount.amount)
  const isOutOfStock = Number(subtotalAmount.amount) === 0

  return (
    <div className="flex gap-4 relative">
      <UI.Media
        src={line.merchandise.image?.url}
        alt={line.merchandise.image?.altText || ""}
        className="size-32 object-contain bg-gray-2 overflow-hidden shrink-0"
      />
      <div className="flex-1 flex flex-col gap-3 justify-between">
        <div>
          <p className="text-sm">{line.merchandise.product.title}</p>
          {isOutOfStock ? (
            <span className="text-sm text-gray-11">
              {toSentenceCase(lang.terms.OUT_OF_STOCK)}
            </span>
          ) : (
            <CartPriceBreakdown
              subtotalAmount={subtotalAmount}
              totalAmount={totalAmount}
              hasDiscount={hasDiscount}
              className="text-sm"
            />
          )}
          <p className="text-xs mt-3">{optionsText}</p>
        </div>
        <div className="flex items-center justify-between">
          <LineQuantitySelector line={line} disabled={isUpdating} />
          <RemoveLineButton lineId={line.id} disabled={isUpdating} />
        </div>
      </div>
      {isUpdating && (
        <div className="absolute inset-0 bg-background opacity-80 flex items-center justify-center">
          <Icon.Loading className="size-3" />
        </div>
      )}
    </div>
  )
}

function RemoveLineButton({
  lineId,
  ...props
}: JSX.IntrinsicElements["button"] & { lineId: string }) {
  const lang = useLang()
  return (
    <CartForm
      route="/cart"
      inputs={{ lineIds: [lineId] }}
      action={CartForm.ACTIONS.LinesRemove}
      fetcherKey={CART_FETCHERS.REMOVE_LINES}
    >
      <UI.LinkButton type="submit" size="sm" {...props}>
        {toSentenceCase(lang.terms.REMOVE)}
      </UI.LinkButton>
    </CartForm>
  )
}

function LineQuantitySelector({ line, disabled }: { line: Line; disabled?: boolean }) {
  const prevQuantity = line.quantity - 1
  const nextQuantity = line.quantity + 1

  return (
    <div className="flex items-center gap-4 shrink-0">
      <UpdateLineQuantityButton
        aria-label="Decrease quantity"
        line={{ id: line.id, quantity: prevQuantity }}
        disabled={disabled}
      >
        <Icon.Minus />
      </UpdateLineQuantityButton>
      <span className="tabular-nums text-center text-sm select-none">
        {line.quantity}
      </span>
      <UpdateLineQuantityButton
        aria-label="Increase quantity"
        line={{ id: line.id, quantity: nextQuantity }}
        disabled={disabled}
      >
        <Icon.Plus />
      </UpdateLineQuantityButton>
    </div>
  )
}

function UpdateLineQuantityButton({
  line,
  children,
  ...props
}: React.PropsWithChildren<
  JSX.IntrinsicElements["button"] & {
    line: CartLineUpdateInput
  }
>) {
  return (
    <CartForm
      route="/cart"
      inputs={{ lines: [line] }}
      action={CartForm.ACTIONS.LinesUpdate}
      fetcherKey={CART_FETCHERS.UPDATE_LINES}
    >
      <UI.Button
        type="submit"
        variant="ghost"
        size="sm"
        className="flex items-center justify-center"
        {...props}
      >
        {children}
      </UI.Button>
    </CartForm>
  )
}

// #endregion

// #region CartPriceBreakdown

function CartPriceBreakdown({
  subtotalAmount,
  totalAmount,
  hasDiscount,
  className,
  ...props
}: {
  subtotalAmount: MoneyV2
  totalAmount: MoneyV2
  hasDiscount: boolean
} & Omit<JSX.IntrinsicElements["div"], "children">) {
  return (
    <div className={UI.cx("flex gap-2", className)} {...props}>
      <CartPrice price={subtotalAmount}>
        {(formattedPrice) => (
          <p
            data-discount={hasDiscount}
            className="data-[discount=true]:line-through data-[discount=true]:text-gray-11"
          >
            {formattedPrice}
          </p>
        )}
      </CartPrice>
      {hasDiscount && (
        <CartPrice price={totalAmount}>
          {(formattedPrice) => <span>{formattedPrice}</span>}
        </CartPrice>
      )}
    </div>
  )
}

// #endregion

// #region CartSummary

function CartSummary() {
  const lang = useLang()
  const { cart } = useCartContext()

  const teamCarts = cart.teamCarts

  const totalPointsDiscountAmount = cart.totalPointsDiscountAmount

  const pointsToEarn = teamCarts.reduce(
    (acc, teamCart) => acc + teamCart.pointsToEarn,
    0,
  )

  const teamCartsWithPointsToEarn = teamCarts.filter(
    (teamCart) => teamCart.pointsToEarn > 0,
  )

  return (
    <div className="flex flex-col px-3 pt-3 pb-4 bshadow-t bshadow-gray-3">
      <div className="flex flex-col gap-2 text-sm text-gray-11">
        <div className="flex items-center justify-between">
          <span>{toSentenceCase(lang.terms.SHIPPING)}:</span>
          <span>{toSentenceCase(lang.terms.SHIPPING_COST)}</span>
        </div>
        {totalPointsDiscountAmount && (
          <div className="flex items-center justify-between">
            <span>{toSentenceCase(lang.terms.TOTAL_DISCOUNT)}:</span>
            <CartPrice price={totalPointsDiscountAmount}>
              {(formattedPrice) => (
                <span className="text-tomato-10">{`-${formattedPrice}`}</span>
              )}
            </CartPrice>
          </div>
        )}
        {teamCarts.length > 1 && (
          <span className="text-xs">
            {toSentenceCase(lang.terms.ITEMS_FROM_DIFFERENT_CREATORS)}
          </span>
        )}
      </div>
      {pointsToEarn > 0 && teamCarts.length > 1 ? (
        <Collapsible.Root id="points-earned" defaultOpen={false}>
          <Collapsible.Trigger className="my-4 gap-1">
            <span className="text-gray-11 text-xs">
              {toSentenceCase(lang.terms.YOU_WILL_EARN)}:{" "}
              <span className="font-departure-mono uppercase text-[11px] leading-[14px] text-gray-12 ml-1">
                +{pointsToEarn} {lang.terms.POINTS}
              </span>
            </span>
          </Collapsible.Trigger>
          <Collapsible.Content className="flex flex-col gap-3 data-[open=true]:mb-4">
            {teamCartsWithPointsToEarn.map((teamCart) => (
              <div key={teamCart.team.id} className="flex items-center gap-2">
                <UI.Avatar
                  variant="rounded"
                  src={teamCart.team.iconUrl ?? ""}
                  alt={teamCart.team.name}
                  className="size-5 overflow-hidden shrink-0"
                />
                <div className="flex items-baseline gap-1.5">
                  <span className="text-sm">{teamCart.team.name}</span>
                  <span className="text-[11px] leading-[14px] font-departure-mono uppercase">
                    {teamCart.pointsToEarn} {lang.terms.POINTS}
                  </span>
                </div>
              </div>
            ))}
          </Collapsible.Content>
        </Collapsible.Root>
      ) : (
        <span className="text-gray-11 text-xs my-4">
          {toSentenceCase(lang.terms.YOU_WILL_EARN)}:{" "}
          <span className="font-departure-mono uppercase text-[11px] leading-[14px] text-gray-12 ml-1">
            +{pointsToEarn} {lang.terms.POINTS}
          </span>
        </span>
      )}
      <p className={UI.cx("text-xs text-gray-11", pointsToEarn === 0 && "mt-4")}>
        {toSentenceCase(lang.terms.AGREE_WITH_TERMS)}
        <br />
        {[
          {
            text: toSentenceCase(lang.terms.TERMS_AND_CONDITIONS),
            url: TERMS_AND_SERVICES_URL,
          },
          { text: toSentenceCase(lang.terms.PRIVACY_POLICY), url: PRIVACY_POLICY_URL },
        ].map(({ text, url }, index) => (
          <React.Fragment key={text}>
            <Link
              to={url}
              className="underline underline-offset-4"
              target="_blank"
              rel="noreferrer"
            >
              {text}
            </Link>
            {index === 0 && ` ${lang.terms.AND} `}
          </React.Fragment>
        ))}
      </p>
    </div>
  )
}

// #endregion

// #region CartPrice

function CartPrice({
  price,
  children,
}: {
  price: MoneyV2 | null
  children: (formattedPrice: string) => React.ReactNode
}) {
  if (!price) return null
  const formattedPrice = useMoney(price)
  return children(formattedPrice.localizedString)
}

// #endregion

// #region Footer

function CartFooter() {
  const lang = useLang()
  const { cart } = useCartContext()

  const hasDiscount = Boolean(cart.totalPointsDiscountAmount)

  return (
    <div className="shrink-0 px-3 pb-3">
      <div className="flex items-center justify-between text-sm bshadow-t bshadow-gray-3 py-3">
        <span>{toSentenceCase(lang.terms.SUBTOTAL)}</span>
        <CartPriceBreakdown
          subtotalAmount={cart.cost.subtotalAmount}
          totalAmount={cart.cost.totalAmount}
          hasDiscount={hasDiscount}
        />
      </div>
      <CheckoutButton />
    </div>
  )
}

function CheckoutButton() {
  const lang = useLang()
  const { cart } = useCartContext()
  const iykRef = IykRef.getFromUrl(window.location.href)

  return (
    <CartForm
      route="/cart"
      action={CUSTOM_CART_ACTIONS.ADD_REFERRAL_DATA}
      inputs={{ iykRef, redirectTo: cart.checkoutUrl }}
      fetcherKey={CART_FETCHERS.ADD_REFERRAL_DATA}
    >
      {(fetcher) => (
        <UI.Button
          type="submit"
          className={UI.classForButton({ size: "lg", className: "w-full" })}
        >
          {fetcher.state === "idle" ? (
            toSentenceCase(lang.terms.CHECKOUT)
          ) : (
            <Icon.Loading />
          )}
        </UI.Button>
      )}
    </CartForm>
  )
}

// #endregion Footer

type Line = CartData["teamCarts"][number]["lines"][number]

// #region Exports

export { Cart }

// #endregion
