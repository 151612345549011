import * as Icon from "@iyk/icons"
import * as ReactDialog from "@radix-ui/react-dialog"
import * as React from "react"

import { cva, type VariantProps } from "@iyk/ui"

const Sheet = ReactDialog.Root

const SheetTrigger = ReactDialog.Trigger

const SheetClose = ReactDialog.Close

const SheetPortal = ReactDialog.Portal

// #region SheetOverlay

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Overlay>,
  React.ComponentPropsWithoutRef<typeof ReactDialog.Overlay>
>(({ className, ...props }, ref) => (
  <ReactDialog.Overlay
    className={classForSheetOverlay({ className })}
    {...props}
    ref={ref}
  />
))

SheetOverlay.displayName = ReactDialog.Overlay.displayName

const classForSheetOverlay = cva([
  "fixed inset-0 z-50",

  "bg-black/80 backdrop-blur-[1px]",

  "data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
])

// #endregion

// #region SheetContent

const classForSheetContent = cva(
  [
    "fixed z-50",
    "bg-background",
    "transition ease-in-out data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:duration-300 data-[state=open]:duration-500",
  ],
  {
    variants: {
      side: {
        top: "inset-x-0 top-0 data-[state=closed]:slide-out-to-top data-[state=open]:slide-in-from-top",
        bottom:
          "inset-x-0 bottom-0 data-[state=closed]:slide-out-to-bottom data-[state=open]:slide-in-from-bottom",
        left: "inset-y-0 left-0 h-full w-full max-w-md data-[state=closed]:slide-out-to-left data-[state=open]:slide-in-from-left",
        right:
          "inset-y-0 right-0 h-full w-full max-w-md data-[state=closed]:slide-out-to-right data-[state=open]:slide-in-from-right",
      },
      size: {
        none: "",
        sm: "p-2",
        md: "p-4",
        lg: "p-6",
      },
    },

    defaultVariants: {
      side: "right",
      size: "md",
    },
  },
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof ReactDialog.Content>,
    VariantProps<typeof classForSheetContent> {}

const SheetContent = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Content>,
  SheetContentProps
>(({ side, size, className, children, ...props }, ref) => (
  <SheetPortal>
    <SheetOverlay />
    <ReactDialog.Content
      ref={ref}
      className={classForSheetContent({ side, size, className })}
      {...props}
    >
      {children}
      <ReactDialog.Close className="text-gray-11 p-2 absolute right-1.5 top-1.5 transition-colors duration-200 hover:bg-gray-3 hover:text-gray-12 disabled:pointer-events-none focus-within:outline focus-within:outline-gray-10">
        <Icon.Cross className="size-4" />
        <span className="sr-only">Close</span>
      </ReactDialog.Close>
    </ReactDialog.Content>
  </SheetPortal>
))

SheetContent.displayName = ReactDialog.Content.displayName

// #endregion

// #region SheetHeader

const SheetHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classForSheetHeader({ className })} {...props} />
)

SheetHeader.displayName = "SheetHeader"

const classForSheetHeader = cva(["flex flex-col space-y-2", "text-center sm:text-left"])

// #endregion

// #region SheetFooter

const SheetFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classForSheetFooter({ className })} {...props} />
)

SheetFooter.displayName = "SheetFooter"

const classForSheetFooter = cva([
  "flex flex-col-reverse sm:flex-row sm:justify-end sm:gap-x-2",
])

// #endregion

// #region SheetTitle

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Title>,
  React.ComponentPropsWithoutRef<typeof ReactDialog.Title>
>(({ className, ...props }, ref) => <ReactDialog.Title ref={ref} {...props} />)

SheetTitle.displayName = ReactDialog.Title.displayName

// #endregion

// #region SheetDescription

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof ReactDialog.Description>,
  React.ComponentPropsWithoutRef<typeof ReactDialog.Description>
>(({ className, ...props }, ref) => <ReactDialog.Description ref={ref} {...props} />)

SheetDescription.displayName = ReactDialog.Description.displayName

// #endregion

export {
  SheetClose as Close,
  SheetContent as Content,
  SheetDescription as Description,
  SheetFooter as Footer,
  SheetHeader as Header,
  SheetOverlay as Overlay,
  SheetPortal as Portal,
  Sheet as Root,
  SheetTitle as Title,
  SheetTrigger as Trigger,
}
