import type { CartActionInput } from "@shopify/hydrogen"

import * as React from "react"

import { isEqual } from "@iyk/lodash"
import { useFetchers } from "@remix-run/react"
import { CartForm } from "@shopify/hydrogen"
import { isCartFetcherKey } from "./cart-fetchers.ts"

export function usePendingCartActions() {
  const fetchers = useFetchers()
  const [pendingCartActions, setPendingCartActions] = React.useState<CartActionInput[]>(
    [],
  )

  React.useEffect(() => {
    const newPendingCartActions = fetchers
      .map((fetcher) =>
        isCartFetcherKey(fetcher.key) && fetcher.state !== "idle" && fetcher.formData
          ? CartForm.getFormInput(fetcher.formData)
          : null,
      )
      .filter((action): action is CartActionInput => Boolean(action))

    setPendingCartActions((prevPendingCartActions) => {
      if (isEqual(newPendingCartActions, prevPendingCartActions)) {
        return prevPendingCartActions
      }
      return newPendingCartActions
    })
  }, [fetchers])

  return {
    hasPendingActions: pendingCartActions.length > 0,
    pendingCartActions,
  }
}
