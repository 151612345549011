import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as Shopify from "../../lib/shopify/shopify.ts"

import { toSentenceCase } from "@iyk/string"
import { useLang } from "../../lib/lang/use-lang.ts"
import { ItemMedia } from "../profiles/item-media.tsx"
import { useCart } from "./cart-provider.tsx"
import { useAddToCart } from "./use-add-to-cart.ts"

export function CartNotification({ duration = 3000 }: CartNotificationProps) {
  const { addedVariant } = useAddToCart()
  const cartContext = useCart()
  const lang = useLang()

  const [isVisible, setIsVisible] = React.useState(false)
  const timerRef = React.useRef<Timer>()

  React.useEffect(() => {
    if (isVisible) {
      timerRef.current = setTimeout(() => {
        setIsVisible(false)
      }, duration)
    }

    return () => clearTimeout(timerRef.current)
  }, [isVisible])

  React.useEffect(() => {
    if (addedVariant) {
      setIsVisible(true)
    }
  }, [addedVariant])

  const onViewCartClick = () => {
    setIsVisible(false)
    cartContext.open()
  }

  return (
    <div className={classForCartNotificationContainer({ isVisible })}>
      <div className="w-full px-4 py-4 flex flex-col gap-4 md:py-0 md:flex-row md:justify-between md:items-center">
        <div className="flex items-center gap-x-1">
          <Icon.Check className="size-4" />
          <UI.Text className="text-sm">Added to bag</UI.Text>
        </div>
        <div className="flex flex-col gap-4 md:flex-row">
          {addedVariant && (
            <div className="flex items-center gap-x-4">
              <ItemMedia
                className="w-16 md:w-9"
                src={addedVariant.image?.url}
                alt={addedVariant.image?.altText ?? ""}
              />
              <div>
                <UI.Text className="text-sm">{addedVariant.product.title}</UI.Text>
                <UI.Text className="text-xs">
                  {Shopify.getFormattedVariantOptions(addedVariant.selectedOptions)}
                </UI.Text>
              </div>
            </div>
          )}
          <UI.Button
            variant="outline"
            className="w-full md:w-auto"
            onClick={onViewCartClick}
          >
            {toSentenceCase(lang.terms.VIEW_CART)}
          </UI.Button>
        </div>
      </div>
    </div>
  )
}

const classForCartNotificationContainer = UI.cva(
  [
    "fixed top-0 left-0 right-0 z-[100]",
    "flex items-center",
    "bg-background bshadow-b bshadow-gray-6",
    "transition-all duration-300",
    "md:h-[var(--header-height)]",
  ],
  {
    variants: {
      isVisible: {
        true: "translate-y-0 opacity-100",
        false: "-translate-y-full opacity-0",
      },
    },
  },
)

type CartNotificationProps = {
  duration?: number
}
