import type { OptimisticCartLineInput } from "@shopify/hydrogen"
import type { ProductVariantFragment } from "../../lib/shopify/types/storefront.generated"
import type { action as cartAction } from "../../routes/cart.ts"

import * as Sentry from "@sentry/remix"
import * as React from "react"

import { useFetcher } from "@remix-run/react"
import { CartForm } from "@shopify/hydrogen"
import { CART_FETCHERS } from "./cart-fetchers.ts"
import { hasCartResponseErrors } from "./use-cart-errors.ts"

//#region Hook

export function useAddToCart(): UseAddToCartReturnType {
  const fetcher = useFetcher<typeof cartAction>({ key: CART_FETCHERS.ADD_LINES })

  const lastSubmission = React.useRef<LinesAddInput | null>(null)

  const [data, setData] = React.useState<{
    addedVariant: ProductVariantFragment | null
  }>({
    addedVariant: null,
  })

  const handleCartUpdate = (linesAddInput: LinesAddInput) => {
    try {
      const selectedVariant = linesAddInput.lines[0].selectedVariant
      if (!selectedVariant) throw new Error("SelectedVariant does not exist")
      setData({ addedVariant: selectedVariant as ProductVariantFragment })
    } catch (err) {
      // NOTE: We are not capturing errors to handle errors but to know when the Shopify API is changing
      const error = err instanceof Error ? err : new Error(String(err))
      Sentry.captureException(error)
    }
  }

  if (fetcher.state === "submitting" && fetcher.formData) {
    const cartAction = CartForm.getFormInput(fetcher.formData)
    if (cartAction && cartAction.action === "LinesAdd") {
      lastSubmission.current = cartAction.inputs
    }
  }

  React.useEffect(() => {
    if (
      fetcher.state === "idle" &&
      fetcher.data &&
      fetcher.data.success &&
      !hasCartResponseErrors(fetcher.data.data) &&
      lastSubmission.current
    ) {
      handleCartUpdate(lastSubmission.current)
    }
  }, [fetcher.state, fetcher.data])

  return data
}

//#endregion

//#region Types

type LinesAddInput = {
  lines: Array<OptimisticCartLineInput>
}

export type UseAddToCartReturnType = {
  addedVariant: ProductVariantFragment | null
}

//#endregion
